import { PATCH } from "@/core/services/store/request.module";

export default {
  methods: {
    patchCustomer(customer, data) {
      const _this = this;
      return new Promise((resolve, reject) => {
        if (customer) {
          _this.$store
            .dispatch(PATCH, { url: "customer/" + customer, data })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              resolve(error);
            });
        } else {
          reject("Customer is required");
        }
      });
    },
    patchCustomerContactPerson(customer, persons, data) {
      const _this = this;
      return new Promise((resolve, reject) => {
        if (customer) {
          _this.$store
            .dispatch(PATCH, {
              url: "customer/" + customer + "/contact-person/" + persons,
              data,
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              resolve(error);
            });
        } else {
          reject("Customer is required");
        }
      });
    },
    patchCustomerProperty(customer, properties, data) {
      const _this = this;
      return new Promise((resolve, reject) => {
        if (customer) {
          _this.$store
            .dispatch(PATCH, {
              url: "customer/" + customer + "/property-address/" + properties,
              data,
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              resolve(error);
            });
        } else {
          reject("Customer is required");
        }
      });
    },
    patchCustomerBilling(customer, data) {
      const _this = this;
      return new Promise((resolve, reject) => {
        if (customer) {
          _this.$store
            .dispatch(PATCH, {
              url: "customer/" + customer + "/billing-address",
              data,
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              resolve(error);
            });
        } else {
          reject("Customer is required");
        }
      });
    },
  },
};
