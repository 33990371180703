var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-layout',[_c('v-col',{staticClass:"custom-content-container",attrs:{"cols":"12","md":12,"sm":12}},[_c('perfect-scrollbar',{staticClass:"scroll",staticStyle:{"max-height":"60vh","position":"relative"},attrs:{"options":{ suppressScrollX: true }}},[_c('v-layout',{staticClass:"d-block"},[_c('v-row',[_c('v-col',{staticClass:"iv-custom-field",attrs:{"cols":12,"md":12,"sm":12}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-switch',{staticClass:"mt-0",attrs:{"inset":"","color":"cyan","label":"Client reminders sent for upcoming visits."},model:{value:(_vm.notification.client_reminders),callback:function ($$v) {_vm.$set(_vm.notification, "client_reminders", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"notification.client_reminders"}}),_c('v-switch',{staticClass:"mt-0",attrs:{"inset":"","color":"cyan","label":"Client follow-up emails when you close a job."},model:{value:(_vm.notification.client_follow_up_emails),callback:function ($$v) {_vm.$set(_vm.notification, "client_follow_up_emails", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"notification.client_follow_up_emails"}}),_c('v-switch',{staticClass:"mt-0",attrs:{"inset":"","color":"cyan","label":"Print email on document when you download."},model:{value:(_vm.notification.print_email_on_work_order),callback:function ($$v) {_vm.$set(_vm.notification, "print_email_on_work_order", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"notification.print_email_on_work_order"}}),_c('hr'),_c('label',{staticClass:"font-size-16 font-weight-500"},[_vm._v("Remarks (Internal Use Only)")]),_c('v-textarea',{attrs:{"rules":[
                  _vm.validateRules.minLength(
                    _vm.notification.customer_remark,
                    'Remarks',
                    2
                  ),
                  _vm.validateRules.maxLength(
                    _vm.notification.customer_remark,
                    'Remarks',
                    512
                  ),
                ],"auto-grow":"","dense":"","filled":"","color":"cyan","label":"Remarks","solo":"","flat":"","row-height":"30"},model:{value:(_vm.notification.customer_remark),callback:function ($$v) {_vm.$set(_vm.notification, "customer_remark", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"notification.customer_remark"}})],1)],1)],1)],1)],1)],1),(false && !_vm.isDialog)?[_c('v-col',{staticClass:"custom-sidebar-container grey lighten-3",attrs:{"cols":"12","md":"3","sm":"3"}},[_vm._l((_vm.fieldDescriptions),function(fieldInfo,indx){return [(fieldInfo.field == _vm.currentActiveField)?_c('div',{key:indx,staticClass:"help-sidebar"},[_c('div',{staticClass:"help-sidebar-header"},[(fieldInfo.title)?_c('h3',{staticClass:"section-header-title"},[_vm._v(" "+_vm._s(fieldInfo.title)+" ")]):_vm._e()]),(fieldInfo.description)?_c('p',{staticClass:"help-sidebar-description"},[_vm._v(" "+_vm._s(fieldInfo.description)+" ")]):_vm._e(),(fieldInfo.footer)?_c('h5',{staticClass:"help-sidebar-footer-title"},[_vm._v(" "+_vm._s(fieldInfo.footer)+" ")]):_vm._e(),(fieldInfo.footer_content)?_c('p',{staticClass:"help-sidebar-footer-text"},[_vm._v(" "+_vm._s(fieldInfo.footer_content)+" ")]):_vm._e()]):_vm._e()]})],2)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }